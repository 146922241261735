import { SPORT_IDS, CYBER_SPORT_ID } from 'markets-store/constants'

const HEADER_BACKGROUNDS = {
  [SPORT_IDS.SOCCER]: '--background-accent-sport-soccer',
  [SPORT_IDS.BADMINTON]: '--background-accent-sport-badminton',
  [SPORT_IDS.BASKETBALL]: '--background-accent-sport-basketball',
  [SPORT_IDS.BASKETBALL_STREET]: '--background-accent-sport-basketball-3-3',
  [SPORT_IDS.VOLLEYBALL_BEACH]: '--background-accent-sport-volleyball',
  [SPORT_IDS.VOLLEYBALL]: '--background-accent-sport-volleyball',
  [SPORT_IDS.CRICKET]: '--background-accent-sport-cricket',
  [SPORT_IDS.BOWLES]: '--background-accent-sport-bowls-game',
  [SPORT_IDS.DARTS]: '--background-accent-sport-darts',
  [SPORT_IDS.TENNIS]: '--background-accent-sport-tennis',
  [SPORT_IDS.TABLE_TENNIS]: '--background-accent-sport-table-tennis',
  [SPORT_IDS.ICE_HOCKEY]: '--background-accent-sport-hockey',
  [CYBER_SPORT_ID]: '--background-accent-sport-cybersport',
  [SPORT_IDS.HANDBALL]: '--background-accent-sport-handball',
  [SPORT_IDS.FOOTSAL]: '--background-accent-sport-footzal',
  [SPORT_IDS.CYCLING]: '--background-accent-sport-cycling',
  [SPORT_IDS.MMA]: '--background-accent-sport-mma',
  [SPORT_IDS.SNOOKER]: '--background-accent-sport-snooker',
  [SPORT_IDS.FORMULA]: '--background-accent-sport-formula1',
  [SPORT_IDS.AMERICAN_FOOTBALL]: '--background-accent-sport-american-football',
  [SPORT_IDS.RUGBY]: '--background-accent-sport-rugby',
  [SPORT_IDS.BOXING]: '--background-accent-sport-boxing',
  [SPORT_IDS.BALL_HOCKEY]: '--background-accent-sport-hockey-with-a-ball',
  [SPORT_IDS.SKIING]: '--background-accent-sport-skiing',
  [SPORT_IDS.FLOORBALL]: '--background-accent-sport-floorball',
  default: '--background-accent-sport-other',
}

const HASH_BACKGROUNDS = {
  [SPORT_IDS.SOCCER]: '#00713B',
  [SPORT_IDS.BADMINTON]: '#444343',
  [SPORT_IDS.BASKETBALL]: '#BF430E',
  [SPORT_IDS.BASKETBALL_STREET]: '#444343',
  [SPORT_IDS.VOLLEYBALL_BEACH]: '#00647A',
  [SPORT_IDS.VOLLEYBALL]: '#00647A',
  [SPORT_IDS.CRICKET]: '#444343',
  [SPORT_IDS.BOWLES]: '#444343',
  [SPORT_IDS.DARTS]: '#3F5A75',
  [SPORT_IDS.TENNIS]: '#004469',
  [SPORT_IDS.TABLE_TENNIS]: '#2B5B51',
  [SPORT_IDS.ICE_HOCKEY]: '#1A2988',
  [CYBER_SPORT_ID]: '#38003D',
  [SPORT_IDS.HANDBALL]: '#3F5A75',
  [SPORT_IDS.FOOTSAL]: '#3F5A75',
  [SPORT_IDS.CYCLING]: '#444343',
  [SPORT_IDS.MMA]: '#06060C',
  [SPORT_IDS.SNOOKER]: '#444343',
  [SPORT_IDS.FORMULA]: '#C00F0F',
  [SPORT_IDS.AMERICAN_FOOTBALL]: '#00713B',
  [SPORT_IDS.RUGBY]: '#444343',
  [SPORT_IDS.BOXING]: '#06060C',
  [SPORT_IDS.BALL_HOCKEY]: '#3F5A75',
  [SPORT_IDS.SKIING]: '#1A2988',
  [SPORT_IDS.FLOORBALL]: '#444343',
  default: '#444343',
}

const BODY_BACKGROUNDS = {
  [SPORT_IDS.SOCCER]: '--background-primary',
  default: '--background-primary',
}
export function getEventBackground(sportId: number | string) {
  return {
    header:
      HEADER_BACKGROUNDS[sportId as keyof typeof HEADER_BACKGROUNDS] ??
      HEADER_BACKGROUNDS.default,
    body:
      BODY_BACKGROUNDS[sportId as keyof typeof BODY_BACKGROUNDS] ??
      BODY_BACKGROUNDS.default,
    hashColor:
      HASH_BACKGROUNDS[sportId as keyof typeof HASH_BACKGROUNDS] ??
      HASH_BACKGROUNDS.default,
  }
}
